<template>
  <div class="login-wrap">
    <div class="title-wrap">工程师<span>小威</span></div>
    <div class="detail">工程师小威，汽车人的智能大脑</div>
    <div class="login-form">
      <el-form :model="loginForm" :rules="rules" ref="loginForm">
        <el-form-item prop="phone">
          <el-input v-model="loginForm.phone" maxlength="11" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item prop="code">
          <div class="code-wrap">
            <div class="code-box">
              <el-input v-model="loginForm.code" maxlength="6" placeholder="请输入验证码" />
            </div>
            <div class="code-btn">发送验证码</div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button class="login-btn" @click="login">登录</el-button>
        </el-form-item>
        <el-form-item prop="checked" class="checkbox-form">
          <el-checkbox-group class="check-box" v-model="loginForm.checked">
            <el-checkbox>
              <p>未注册手机号将自动注册，勾选即代表您同意<span @click.stop="toAgreement()">服务条款、</span><span @click.stop="toPrivacyPolicy()">隐私政策</span></p>
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
const validatePhone = (rule, value, callback) => {
  if (!value) {
    return false;
  }
  const phoneRegExp = /^1[23456789]\d{9}$/;
  if (phoneRegExp.test(value)) {
    callback()
  } else {
    return callback(new Error('请输入正确手机号'))
  }
};
export default {
  name: "appLogin",
  data() {
    return {
      loginForm: {
        phone: '',
        code: '',
        checked: []
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhone, message: '请输入正确手机号', trigger: 'blur' }
        ],
        code: [{required: true, message: '请输入验证码', trigger: 'blur'}],
        checked: [{ type: 'array', required: true, message: '请勾选服务条款、隐私政策', trigger: 'change'}]
      }
    }
  },
  methods: {
    login() {
      this.$refs.loginForm.validate((valid) => {
        console.log(valid)
      })
    },
    /**
     * 用户协议
     * */
    toAgreement() {
      this.$router.push({
        path: '/agreement'
      })
    },
    /**
     * 隐私政策
     * */
    toPrivacyPolicy() {
      this.$router.push({
        path: '/privacyPolicy'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: url("../assets/images/home_bg.png") no-repeat;
  background-size: cover;
  .title-wrap {
    display: flex;
    align-items: center;
    font-size: 48px;
    font-family: PingFangSC;
    font-weight: 600;
    color: rgba(0,0,0,0.88);
    margin-bottom: 24px;
    span {
      color: #1777FF;
    }
  }
  .detail {
    font-size: 16px;
    font-family: PingFangSC;
    font-weight: 400;
    color: rgba(0,0,0,0.88);
    margin-bottom: 56px;
  }
  .login-form {
    width: 360px;
    .el-input__inner {
      color: rgba(0,0,0,0.88);
      height: 46px;
      line-height: 46px;
      border-radius: 4px;
      border: 1px solid #D6D6D6;
      font-size: 16px;
    }
    .el-input__inner:hover, .code-wrap:hover {
      border-color: #1777FF;
    }
    .code-wrap {
      display: flex;
      width: 100%;
      border: 1px solid #D6D6D6;
      padding-right: 12px;
      align-items: center;
      height: 46px;
      border-radius: 4px;
      background: #FFFFFF;
      .el-input__inner {
        height: 44px;
        line-height: 44px;
      }
      .code-box {
        width: 240px;
        margin-right: 12px;
        ::v-deep .el-input__inner {
          border: none;
        }
      }
      .code-btn {
        flex: 1;
        display: flex;
        cursor: pointer;
        justify-content: flex-end;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #1677FF;
      }
    }
    .login-btn {
      width: 376px;
      height: 48px;
      background: #1777FF;
      font-size: 16px;
      font-family: PingFangSC;
      font-weight: 500;
      color: #FFFFFF;
      border-radius: 24px;
      margin-top: 44px;
      margin-left: -8px;
    }
    .checkbox-form {
      ::v-deep .el-form-item__content {
        line-height: 13px;
      }
    }
    .check-box {
      width: 376px;
      margin-left: -8px;
      p {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: rgba(0,0,0,0.45);
        span {
          color: #1777FF;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
